<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    
    return {
      title: 'Users',

      breadcrumbs: [
        
      ]
    };
  },
  created() {
    this.loadAccounts();
    this.loadUsers();
  },
  watch: {
   
  },
  computed: {
    isBusy (){
      return this.loadingAccounts || this.loadingUsers;
    },
    ...mapGetters('auth', {
        permissios: 'userPermissions',
        currentUser: 'currentUser',
        userType: 'userType',
        userRole: 'userRole',
        managedAccounts: 'managedAccounts'
    }),
    ...mapGetters('accounts', {
        loadingAccounts: 'loading',
        accounts: 'items'
    }),
    ...mapGetters('users', {
        loadingUsers: 'loading',
        users: 'items',
        total: 'total',
        perPage: 'perPage',
        page: 'page',
        filter: 'filter',
    })
  },
  methods: {
     ...mapActions('accounts', {
        loadAccounts: 'load'
    }),
    ...mapActions('users', {
        loadUsers: 'load',
        setPage: 'setPage',
        setFilter: 'setFilter'
    }),
    allowCreate() {
      return this.userRole.find(x => x === 'administrator')
        || (this.userType === 'C8' && this.managedAccounts.length > 0);
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="isBusy"/>
    <div class="row">
      <div class="col-12">
        <div class="email-leftbar card p-0 cardc8" >
          <div class="card-content m-3">
            <router-link
              :to="{name: 'users-edit', params: { id: 'new'}}"
              class="btn btn-secondary mb-4 btn-block"
            >
              <i class="fa fa-plus mr-2"/>
              NEW USER
            </router-link>

            <input type="text" class="form-control mb-2" placeholder="Search..."  @input="($event) => setFilter({...filter, keyword: $event.target.value})" :value="filter.keyword"/>
            <h6 class="mt-4" v-if="userType === 'C8'">User Type</h6>
            <div v-if="userType === 'C8'">
              <select class="form-control" @input="($event) => setFilter({...filter, userType:  $event.target.value})" :value="filter.userType">
                <option :value="null">All</option>
                <option value="C8">C8</option>
                <option value="Account">Account</option>
              </select>
            </div>
            <h6 class="mt-4" v-if="userType === 'C8'">Accounts</h6>
            <select v-if="userType === 'C8'" class="form-control" @input="($event) => setFilter({...filter, accountId: $event.target.value })" :value="filter.accountId">
              <option :value="null">All</option>
              <option v-for="account in accounts" :key="account.id" :value="account.id">{{account.name}}</option>
            </select>
          </div>
        </div>
        <div class="email-rightbar mb-3 p-0">
          <div class="card p-0 cardc8">
            <div class="card-content m-3">
              <div class="table-responsive">
                <table class="table table-centered nowrap"> 
                  <thead>
                    <tr>
                      <th style="width: 60px; max-width: 60px;" v-if="permissios.allowManage"></th>
                      
                      <th class="">E-mail</th>
                      <th class="">Name</th>
                      <th class="">Phone</th>
                      <th class="" v-if="userType === 'C8'">Account</th>
                      <th class="" >Currency</th>
                      <th class="">
                        Locked End
                      </th>
                      <th>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="users.length === 0 && !isBusy">
                      <td colspan="100">
                        <span class="text-muted">No users found.</span>
                      </td>
                    </tr>
                    <tr v-for="user in users" :key="user.id">
                      <td style="width: 60px; max-width: 60px;" v-if="permissios.allowManage">
                        <router-link
                          :to="{name:'users-edit', params: { id: user.id}}"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </router-link>
                        <router-link
                          v-if="currentUser.Roles.find(x => x === 'administrator') && user.userType === 'Account'"
                          title="Available Instruments"
                          :to="{name:'instrument-assignment', params: { targetType: 'users', targetId: user.id}}"
                        >
                          <i class="mdi mdi-tools font-size-18"></i>
                        </router-link>
                        <!-- <a href="javascript:void(0);" class="mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a> -->
                      </td>
                      <td>
                        <a :href="'mailto:' + user.email">{{user.email}}</a>
                      </td>
                      <td>{{user.name}}</td>
                      <td>
                        <a :href="'tel:' + user.phoneNumber">{{user.phoneNumber}}</a>
                      </td>
                      <td v-if="userType === 'C8'">{{(user.account || {}).name}}</td>
                      <td >{{user.defaultCurrency}}</td>
                      <td>
                        <span  v-if="user.lockoutEnd">{{user.lockoutEnd | moment("L")}}</span>
                      </td>
                      <td>
                        <span class="badge badge-primary mr-2" v-if="user.userType ==='Account'">Account User</span>
                        <span class="badge badge-success mr-2" v-if="user.userRole.includes('Admin')">Admin</span>
                      </td>
                    </tr>
                  </tbody>
                </table>                
              </div>

              <div class="row justify-content-md-between align-items-md-center ml-1 mr-1">
                  <div class="col-xl-7">Total: {{total}}</div>
                  <!-- end col-->
                  <div class="col-xl-5">
                    <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                      <b-pagination
                        @input="(v) => setPage(v)"
                        :value="page"
                        :total-rows="total"
                        :per-page="perPage"
                      >
                      </b-pagination>
                    </div>
                  </div>
                  <!-- end col-->
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>